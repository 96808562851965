import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { decks } from 'cards'
import classnames from 'classnames'

import { ReactComponent as Heart } from './svg/heart.svg'
import { ReactComponent as Spade } from './svg/spade.svg'
import { ReactComponent as Club } from './svg/club.svg'
import { ReactComponent as Diamond } from './svg/diamond.svg'
import { ReactComponent as Donsol } from './svg/donsol.svg'

import styles from './scss/App.module.scss'

const symbolMap = {
  hearts: <Heart />,
  diamonds: <Diamond />,
  clubs: <Club />,
  spades: <Spade />,
  none: <Donsol />
}

const suitMap = {
  hearts: 'Potion',
  diamonds: 'Shield',
  clubs: 'Monster',
  spades: 'Monster',
  none: 'Monster'
}

const rankMap = {
  J: 11,
  Q: 13,
  K: 15,
  A: 17,
  Joker: 21
}

const difficulties = ['easy', 'normal', 'hard', 'expert']

const App = () => {
  const [playing, setPlaying] = useState(false)
  const [restart, setRestart] = useState(0)
  const [roomIndex, setRoomIndex] = useState(0)
  const [difficulty, setDifficulty] = useState('normal')

  const [deck, setDeck] = useState(null)
  const [room, setRoom] = useState([null, null, null, null])
  const [health, setHealth] = useState(21)
  const [defense, setDefense] = useState(0)
  const [roomsPassed, setRoomsPassed] = useState(0)
  const [actionText, setActionText] = useState('Entered the dungeon.')
  const [lastMoveWasPotion, setLastMoveWasPotion] = useState(false)
  const [lastMonsterValue, setLastMonsterValue] = useState(null)
  const [escapedLastRoom, setEscapedLastRoom] = useState(false)
  const [canEscape, setCanEscape] = useState(false)
  const [dead, setDead] = useState(false)

  const [render, setRender] = useState(0)
  const [cardExiting, setCardExiting] = useState(null)

  const audio = new Audio('/sounds/click.mp3')

  useEffect(() => {
    setDeck(null)
    const newDeck = new decks.StandardDeck({ jokers: 2 })
    newDeck.shuffleAll()
    setDeck(newDeck)

    setRoom([null, null, null, null])
    setHealth(21)
    setDefense(0)
    setRoomsPassed(0)
    setActionText('Entered the dungeon.')
    setLastMoveWasPotion(false)
    setLastMonsterValue(null)
    setEscapedLastRoom(false)
    setCanEscape(false)
    setDead(false)
    setCardExiting(null)

    setTimeout(() => {
      setRoomIndex(roomIndex + 1)
      setPlaying(true)
    }, 250)
  }, [restart])

  useEffect(() => {
    if (room.some(card => card !== null)) {
      const currentDeck = deck
      currentDeck.shuffleAll()
      setDeck(currentDeck)
    }

    setRoom([null, null, null, null])

    setTimeout(() => {
      if (deck) {
        const currentDeck = deck
        const newRoomCards = currentDeck.draw(4)
        setRoom(newRoomCards)
        setDeck(currentDeck)
        setCanEscape(false)
        checkForEscape(newRoomCards)
        setLastMoveWasPotion(false)
      }
    }, 250)
  }, [roomIndex])

  const getRoomSize = () => room.filter(x => x !== null).length

  const checkForEscape = currentRoom => {
    const monstersRemaining = currentRoom.filter(x => {
      if (x) {
        return (
          x.suit.name === 'clubs' ||
          x.suit.name === 'spades' ||
          x.suit.name === 'none'
        )
      }
      return null
    }).length

    const cardsRemaining = currentRoom.filter(x => x !== null).length

    if (difficulty === 'easy') {
      if (monstersRemaining === 0) {
        setCanEscape(true)
      }
    } else if (difficulty === 'normal') {
      if (monstersRemaining === 0 && !escapedLastRoom) {
        setCanEscape(true)
      }
    } else if (difficulty === 'hard') {
      if (monstersRemaining === 0 && !escapedLastRoom && cardsRemaining === 1) {
        setCanEscape(true)
      }
    }
  }

  const escapeRoom = () => {
    setCardExiting(null)
    setEscapedLastRoom(true)
    setRoomIndex(roomIndex + 1)
    setRoomsPassed(roomsPassed + 1)
    setActionText('You escaped the room.')
  }

  const valueFromRank = rank => {
    if (isNaN(parseInt(rank))) {
      return 11
    } else {
      return parseInt(rank)
    }
  }

  const monsterValueFromRank = rank => {
    if (isNaN(parseInt(rank))) {
      return rankMap[rank]
    } else {
      return parseInt(rank)
    }
  }

  const setHealthCheckDeath = health => {
    if (health <= 0) setDead(true)
    setHealth(health)
  }

  const hit = (card, i) => {
    audio.play()
    setCardExiting(i)

    const rank = card.rank.shortName

    switch (card.suit.name) {
      case 'hearts':
        if (!lastMoveWasPotion) {
          if (health + valueFromRank(rank) > 21) setHealth(21)
          else setHealth(health + valueFromRank(rank))
          setActionText(
            `Drank potion, you gained ${valueFromRank(rank)} health.`
          )
        } else {
          setActionText('Potion wasted!')
        }
        setLastMoveWasPotion(true)
        break
      case 'diamonds':
        setDefense(valueFromRank(rank))
        setLastMonsterValue(null)
        setLastMoveWasPotion(false)
        setActionText(`Equipped shield of strength ${valueFromRank(rank)}.`)
        break
      default:
        if (
          lastMonsterValue === null ||
          monsterValueFromRank(rank) < lastMonsterValue
        ) {
          if (defense > 0) {
            const diff = defense - monsterValueFromRank(rank)
            if (diff < 0) setHealthCheckDeath(health - Math.abs(diff))
            setLastMonsterValue(monsterValueFromRank(rank))
            setActionText(
              `Battled the monster, your shield absorbed ${
                defense > monsterValueFromRank(rank)
                  ? monsterValueFromRank(rank)
                  : defense
              } damage.`
            )
          } else {
            setHealthCheckDeath(health - monsterValueFromRank(rank))
            setActionText(
              `Battled the monster, you took ${monsterValueFromRank(
                rank
              )} damage.`
            )
          }
        } else {
          setDefense(0)
          setLastMonsterValue(null)
          setHealthCheckDeath(health - monsterValueFromRank(rank))
          setActionText(
            `Your shield broke! You took ${monsterValueFromRank(rank)} damage.`
          )
        }

        setLastMoveWasPotion(false)
        break
    }

    // wait for exit animation
    setTimeout(() => {
      const currentRoom = room
      currentRoom[i] = null
      setRoom(currentRoom)

      const currentDeck = deck
      currentDeck.remove(card)
      setDeck(currentDeck)

      setCardExiting(null)

      // force re-render every turn
      setRender(render + 1)

      if (getRoomSize() === 0) {
        setEscapedLastRoom(false)
        setRoomIndex(roomIndex + 1)
        if (!dead) setRoomsPassed(roomsPassed + 1)
        setActionText('You advanced to the next room.')
      }

      checkForEscape(currentRoom)
    }, 150)
  }

  return (
    <>
      <Helmet>
        <script
          async
          defer
          data-domain="donsol.online"
          src="https://analytics.tdjs.tech/js/plausible.js"
        />
      </Helmet>
      <div className={styles.App}>
        <div className={styles.Game}>
          {playing && deck && (
            <>
              <div className={styles.PlayArea}>
                <ul className={styles.Links}>
                  <li
                    onClick={() => {
                      setDifficulty(
                        difficulties[(difficulties.indexOf(difficulty) + 1) % 4]
                      )
                      setRestart(restart + 1)
                    }}
                  >
                    Difficulty: <strong>{difficulty}</strong>
                  </li>
                </ul>
                <div className={styles.Stats}>
                  <div>
                    <div className={styles.StatItem}>
                      <p className={styles.StatNumber}>
                        {health > 0 ? health : 0}
                      </p>
                      <p className={styles.StatText}>Health</p>
                    </div>
                    <div className={styles.StatBar}>
                      <div
                        style={{
                          width: `${(health > 0 ? health / 21 : 0) * 100}%`
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.StatItem}>
                      <p className={styles.StatNumber}>
                        {defense}
                        {lastMonsterValue && <span>{lastMonsterValue}</span>}
                      </p>
                      <p className={styles.StatText}>Defense</p>
                    </div>
                    <div className={styles.StatBar}>
                      <div
                        style={{
                          width: `${(lastMonsterValue &&
                          defense &&
                          lastMonsterValue < defense
                            ? lastMonsterValue / defense
                            : defense === 0
                            ? 0
                            : 1) * 100}%`
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.StatItem}>
                      <p className={styles.StatNumber}>{roomsPassed}</p>
                      <p className={styles.StatText}>Rooms</p>
                    </div>
                    <div className={styles.StatBar}>
                      <div
                        style={{
                          width: `${(roomsPassed / 14) * 100}%`
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <div className={styles.StatItem}>
                      <p className={styles.StatNumber}>
                        {deck.remainingLength + getRoomSize()}
                      </p>
                      <p className={styles.StatText}>Remaining</p>
                    </div>
                    <div className={styles.StatBar}>
                      <div
                        style={{
                          width: `${((deck.remainingLength + getRoomSize()) /
                            54) *
                            100}%`
                        }}
                      />
                    </div>
                  </div>
                </div>
                {deck.remainingLength > 0 && (
                  <>
                    {!dead && (
                      <>
                        <div className={styles.Room}>
                          {room &&
                            room.map((card, i) =>
                              card ? (
                                <div
                                  key={i}
                                  className={classnames(
                                    styles.CardGroup,
                                    card.suit.name === 'hearts' ||
                                      card.suit.name === 'diamonds'
                                      ? styles.Red
                                      : undefined,
                                    cardExiting === i && styles.exiting
                                  )}
                                  onClick={() => hit(card, i)}
                                >
                                  <div className={styles.Card}>
                                    <p className={styles.TopPip}>
                                      {card.rank.shortName !== 'Joker' &&
                                        card.rank.shortName}
                                    </p>
                                    <p className={styles.Suit}>
                                      {symbolMap[card.suit.name]}
                                    </p>
                                    <p className={styles.BottomPip}>
                                      {card.rank.shortName !== 'Joker' &&
                                        card.rank.shortName}
                                    </p>
                                  </div>
                                  <p className={styles.CardText}>
                                    <strong>{suitMap[card.suit.name]}</strong>{' '}
                                    {card.suit.name === 'clubs' ||
                                    card.suit.name === 'spades' ||
                                    card.suit.name === 'none'
                                      ? monsterValueFromRank(
                                          card.rank.shortName
                                        )
                                      : valueFromRank(card.rank.shortName)}
                                  </p>
                                </div>
                              ) : (
                                <div
                                  key={i}
                                  className={classnames(
                                    styles.CardGroup,
                                    styles.empty
                                  )}
                                >
                                  <div className={styles.CardEmpty} />
                                  <p className={styles.CardIndex}>{i + 1}</p>
                                </div>
                              )
                            )}
                        </div>
                        <p className={styles.ActionText}>{actionText}</p>
                        <button onClick={escapeRoom} disabled={!canEscape}>
                          Escape room
                        </button>
                      </>
                    )}
                    {dead && (
                      <div className={styles.Outcome}>
                        <h2>You died! Game over</h2>
                        <button
                          onClick={() => {
                            setRestart(restart + 1)
                          }}
                        >
                          Play again
                        </button>
                      </div>
                    )}
                  </>
                )}
                {deck.remainingLength === 0 && (
                  <div className={styles.Outcome}>
                    <h2>You won!</h2>
                    <button
                      onClick={() => {
                        setRestart(restart + 1)
                      }}
                    >
                      Play again
                    </button>
                  </div>
                )}
              </div>
              <div className={styles.Rules}>
                <h2>Rules</h2>
                <p>
                  A standard 54 card deck (including jokers) is a dungeon. 4
                  cards are drawn to form a room. The aim of the game is pass
                  all of the rooms and thus clear the dungeon.
                </p>
                <div className={styles.TitleWithSuit}>
                  <Heart />
                  <p>
                    <strong>Hearts are potions</strong>
                  </p>
                </div>
                <p>
                  A potion heals you by it’s face value (face cards are worth
                  11), up to 21 health points. Drinking more than one potion in
                  succession will not heal you more - only the first is
                  effective.
                </p>
                <div className={styles.TitleWithSuit}>
                  <Diamond />
                  <p>
                    <strong>Diamonds are shields</strong>
                  </p>
                </div>
                <p>
                  Shields absorb damage from monsters up to their value. Shields
                  only defend against monstes battled in descending order -
                  attacking a monster with a value higher than or equal to the
                  previous will break your shield and you will take full damage.
                  Picking up a new shield will replace the currently held one.
                </p>
                <div
                  className={classnames(
                    styles.TitleWithSuit,
                    styles.whiteSuits
                  )}
                >
                  <Club />
                  <Spade />
                  <Donsol />
                  <p>
                    <strong>Clubs, Spades, and Jokers are monsters</strong>
                  </p>
                </div>
                <p>
                  Monster cards are as strong as their value. Face cards are as
                  follows: J is 11, Q is 13, K is 15, A is 17; Jokers are both
                  equal to 21.
                </p>
                <p>
                  <strong>Escaping</strong>
                </p>
                <p>
                  Rooms can be escaped, depending on the game difficulty. When
                  escaping a room, the remaining cards are placed back into the
                  deck and the deck is shuffled, meaning that they will appear
                  again later in the dungeon.
                </p>
                <p>You can escape a room when:</p>
                <ul>
                  <li>Easy - All monsters have been defeated,</li>
                  <li>Normal - and you did not escape the previous room,</li>
                  <li>Hard - and there is only one card left in the room</li>
                  <li>Expert - You cannot escape rooms.</li>
                </ul>
                <h2>About</h2>
                <p>
                  This is a web port by{' '}
                  <a href="https://tdjs.tech" target="_blank">
                    Tom Snelling
                  </a>{' '}
                  of an original game. Read more about Donsol here:
                </p>
                <ul>
                  <li>
                    <a
                      href="https://wiki.xxiivv.com/site/donsol.html"
                      target="_blank"
                    >
                      XXIIVV — original game by John Eternal & Devine Lu Linvega
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://boardgamegeek.com/boardgame/197004/donsol"
                      target="_blank"
                    >
                      BoardGameGeek
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default App
